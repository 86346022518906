import $ from "jquery";

// smooth scroll
$(function () {
    $('a[href^="#"]')
        .on('click', function () {
            var adjust = 0;
            var speed = 400;
            var href = $(this).attr('href');
            var target = $(href == '#' || href == '' ? 'html' : href);
            var position = target.offset().top + adjust;
            $('body,html').animate({
                scrollTop: position
            }, speed, 'swing');
            return false;
        });
});

//nav
$(function () {
    $('.menuTrigger').on('click', function () {
        $(this).toggleClass('open');
        $('.head-nav').toggleClass('open');
    });
    $('header .nav a').on('click', function () {
        $('.head-nav').toggleClass('open');
    });
});


//animation
$(window).on('load scroll', function () {
    $('.fadeUp').each(function () {
        var targetElement = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > targetElement - windowHeight + 100) {
            $(this).addClass('scrollin');
        } else {
            $(this).removeClass('scrollin');
        }
    });
});


//Owl Carousel
var $owl = $('.owl-carousel');
$owl.owlCarousel({
    items: 1,
    loop: true,
    center: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    nav: false,
    animateOut: 'fadeOut'
});